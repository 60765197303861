import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { TransactionApi, createTransactionApi } from 'api/transaction-api';

export { HttpClient };
export { TransactionApi };

export const transactionApiContext = createContext<TransactionApi>('transaction-api');
export const createTransactionApiProvider = (httpClient: HttpClient) =>
    new ContextProvider(document.body, {
        context: transactionApiContext,
        initialValue: createTransactionApi(httpClient),
    });
