import { ContextProvider, createContext } from '@lit/context';
import { ConfirmService } from 'components/confirmation/confirm-service';
export { ConfirmService } from 'components/confirmation/confirm-service';

export const confirmServiceContext = createContext<ConfirmService>('confirmService');
export const createConfirmServiceProvider = (confirmService: ConfirmService) =>
    new ContextProvider(document.body, {
        context: confirmServiceContext,
        initialValue: confirmService,
    });
