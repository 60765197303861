// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-element {
  display: flex;
}
.copy-element .copy-box {
  height: 2.5rem;
  width: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/copy-text-element/copy-text-element.scss","webpack://./src/css/_sizes.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAGI;EACI,cCKC;EDJD,aCIC;ADLT","sourcesContent":["@import '/src/css/sizes';\n\n.copy-element {\n    display: flex;\n\n    .copy-box {\n        height: $size-7;\n        width: $size-7;\n    }\n}\n","$font-size-root: 14px;\n\n$size-base: 1rem; // 14\n\n$size-0: 0.125rem; // 1,75\n$size-1: 0.25rem; // 3,5\n$size-2: 0.5rem; // 7\n$size-3: 0.75rem; // 10,5\n$size-4: 1.2rem; // 16.8\n$size-5: 1.5rem; // 21\n$size-6: 2rem; // 28\n$size-7: 2.5rem; //35\n$size-8: 3.5rem; // 49\n$size-9: 5rem; // 70\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
