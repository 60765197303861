import './icon-box.scss';
import { bindable } from 'aurelia-framework';

export class IconBox {
    @bindable color: Color;
    @bindable icon: string;
    @bindable size: number;
}

enum Color {
    Pink,
    Blue,
}
