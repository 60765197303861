import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { CustomerApi, createCustomerApi } from 'api/customer-api';

export { HttpClient };
export { CustomerApi };

export const customerApiContext = createContext<CustomerApi>('customer-api');
export const createCustomerApiProvider = (httpClient: HttpClient) =>
    new ContextProvider(document.body, {
        context: customerApiContext,
        initialValue: createCustomerApi(httpClient),
    });
