import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { UserApi, createUserApi } from 'api/user-api';

export { HttpClient };
export { UserApi };

export const userApiContext = createContext<UserApi>('user-api');
export const createUserApiProvider = (httpClient: HttpClient) =>
    new ContextProvider(document.body, {
        context: userApiContext,
        initialValue: createUserApi(httpClient),
    });
