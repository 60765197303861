import './label-selector.scss';
import ColorHash from 'color-hash';
import { bindable, bindingMode } from 'aurelia-framework';

let colorHash = new ColorHash();

export class LabelSelector {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) editing: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) source: LabelItem[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) items: LabelItem[] = [];
    filteredSource: LabelItem[] = [];
    text: HTMLDivElement;
    show: boolean = false;
    method: () => void;
    selectedIndex: number = null;
    width: number = 0.85;

    attached() {
        this.method = () => this.documentClick();
        this.source = this.source.sort((x, y) => x.name.localeCompare(y.name));
        this.filteredSource = this.source;
        this.items.map((x) => LabelItem.update(x));
    }

    focus($event: Event) {
        $event.stopPropagation();
        this.text.focus();
        this.showMenu();
    }

    keydown($event) {
        // console.log('which', $event.which, $event.key);
        let text = this.text.textContent.trim();

        let focusDropdown = () => {
            let index = this.selectedIndex;

            if (index === -1 || Number.isNaN(index)) {
                return;
            }

            let list = document.getElementsByClassName(`dropdown-item index-${index}`);

            if (list.length === 0) {
                return;
            }

            list[0].scrollIntoView();
        };

        if ($event.key === 'ArrowUp') {
            if (this.selectedIndex === null) {
                this.selectedIndex = this.filteredSource.length - 1;
                this.showMenu();
                focusDropdown();
                return false;
            }

            this.selectedIndex = (this.selectedIndex + this.filteredSource.length - 1) % this.filteredSource.length;
            this.showMenu();
            focusDropdown();
            return false;
        } else if ($event.key === 'ArrowDown') {
            if (this.selectedIndex === null) {
                this.selectedIndex = 0;
                this.showMenu();
                focusDropdown();
                return false;
            }

            this.selectedIndex = (this.selectedIndex + 1) % this.filteredSource.length;
            this.showMenu();
            focusDropdown();
            return false;
        } else if ($event.key === 'Backspace') {
            if (this.items.length !== 0 && this.text.textContent.trim() === '') {
                this.items.splice(this.items.length - 1, 1);
                this.text.textContent = '';
            }
        } else if ($event.key === 'Escape') {
            this.hideMenu();
            return true;
        } else if ($event.key === 'Enter') {
            if (this.selectedIndex !== null) {
                this.add(this.filteredSource[this.selectedIndex].name);
                this.hideMenu();
                return false;
            }

            if (text === '') {
                return false;
            }

            this.add(text);
            return false;
        }

        this.showMenu();
        return true;
    }

    keyup($event) {
        if ($event.key === 'Backspace') {
        } else if ($event.key.length !== 1) {
            return false;
        }

        this.updateFilter();
    }

    updateFilter() {
        let text = this.text.textContent.trim();

        this.filteredSource = this.source.filter((x) => x.name.startsWith(text));
        this.selectedIndex = null;

        if (this.filteredSource.length === 0) {
            this.hideMenu();
        } else {
            this.showMenu();
        }
    }

    showMenu() {
        if (this.filteredSource.length === 0 || this.show) {
            return;
        }

        this.show = true;
        document.addEventListener('click', this.method);
    }

    hideMenu() {
        if (!this.show) {
            return;
        }

        document.removeEventListener('click', this.method);
        this.show = false;
    }

    addItem($event: Event, text: string) {
        $event.stopPropagation();
        this.text.textContent = '';
        this.add(text);
        this.hideMenu();
    }

    detached() {
        document.removeEventListener('click', this.method);
    }

    documentClick() {
        document.removeEventListener('click', this.method);
        this.hideMenu();
    }

    add(name: string) {
        let index = this.items.findIndex((x) => x.name === name);
        this.selectedIndex = null;
        this.text.textContent = '';
        this.hideMenu();
        this.updateFilter();

        if (index !== -1) {
            return;
        }

        this.items.push(new LabelItem(name));
        this.width = 0.75;
    }

    remove($event: Event, name: string) {
        $event.stopPropagation();

        let index = this.items.findIndex((x) => x.name === name);

        if (index === -1) {
            return;
        }

        this.items.splice(index, 1);
        this.hideMenu();
    }

    contentChanged($event: Event) {
        console.log('fisk', $event);
    }
}

export class LabelItem {
    active: boolean = false;
    color: string;
    name: string;

    constructor(name: string) {
        this.name = name;
        LabelItem.update(this);
    }

    static update(item: LabelItem) {
        item.color = colorHash.hex(item.name);
    }
}
