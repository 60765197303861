import { bindable, bindingMode } from 'aurelia-framework';
import { UserModel } from 'user-model';
import ColorHash from 'color-hash';
import './user-bubble.scss';
import { ImagesModel } from 'images-model';

let mixColor = function (color_1, color_2, weight: number) {
    color_1 = color_1.replace(/#/g, '');
    color_2 = color_2.replace(/#/g, '');
    function d2h(d) {
        return d.toString(16);
    } // convert a decimal value to hex
    function h2d(h) {
        return parseInt(h, 16);
    } // convert a hex value to decimal

    weight = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted

    var color = '#';

    for (var i = 0; i <= 5; i += 2) {
        // loop through each of the 3 hex pairs—red, green, and blue
        var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
            v2 = h2d(color_2.substr(i, 2)),
            // combine the current pairs from each source color, according to the specified weight
            val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

        while (val.length < 2) {
            val = '0' + val;
        } // prepend a '0' if val results in a single digit

        color += val; // concatenate val to our new color string
    }

    return color; // PROFIT!
};

export class UserBubbleList {
    @bindable users: UserModel[];
    colorHash: ColorHash;

    constructor() {
        this.colorHash = new ColorHash({ saturation: 0.98, lightness: 0.81 });
        this.users = [];
    }

    getColor(userId: string) {
        let hex = this.colorHash.hex(userId);

        return hex;
    }

    getColorText(userId: string) {
        const bgColor = this.colorHash.hex(userId);

        let textColor = mixColor('000000', bgColor, 40);
        return textColor;
    }
}
