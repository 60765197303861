import { bindable, customElement, children, TaskQueue } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Tab } from './tab';
import { EventAggregator } from 'aurelia-event-aggregator';

@customElement('tabs')
export class Tabs {
    @bindable useHash: boolean = true;
    @children('tab') tabs: Tab[] = [];
    eventAggregator: EventAggregator;
    private activeTab: Tab;
    taskQueue: TaskQueue;

    constructor(taskQueue: TaskQueue, eventAggregator: EventAggregator) {
        this.taskQueue = taskQueue;
        this.eventAggregator = eventAggregator;
    }

    click(tab: Tab, event) {
        event.stopPropagation();

        this.activate(tab);

        if (this.useHash) {
            console.log('hash: ' + this.useHash);
            PLATFORM.location.hash = tab.hash;
        }
    }

    // activateByHash(hash: string) {
    //     let tab = this.tabs.find(t => t.hash == hash);
    //     if (tab) {
    //         this.activate(tab);
    //         PLATFORM.location.hash = tab.hash;
    //     }
    // }

    private activate(tab: Tab) {
        if (this.activeTab) {
            this.activeTab.active = false;
        }

        tab.active = true;
        this.activeTab = tab;
        this.eventAggregator.publish('tab', 'change');
    }

    private updateActiveTab() {
        let hash = PLATFORM.location.hash;

        if (this.useHash && hash !== '') {
            let tabHash = hash.replace('#', '');
            let tab = this.tabs.find((t) => t.hash == tabHash);

            if (tab) {
                this.activate(tab);
                return;
            }
        }

        this.activate(this.tabs[0]);
    }

    attached() {
        this.taskQueue.queueTask(() => {
            if (this.tabs && this.tabs.length > 0) {
                this.updateActiveTab();
            }
        });

        console.log('hash: ' + this.useHash);

        if (this.useHash) {
            PLATFORM.global.addEventListener('hashchange', this.hashchanged);
        }
    }

    detached() {
        if (this.useHash) {
            PLATFORM.global.removeEventListener('hashchange', this.hashchanged);
        }
    }

    hashchanged = (event) => {
        this.updateActiveTab();
    };
}
