import './scrollspy.scss';

export class ScrollSpy {
    anchors: Anchor[] = [];
    active: Anchor = null;
    root: HTMLElement;

    attached() {
        document.addEventListener('scroll', this.scrolled);
        document.addEventListener('resize', this.scrolled);
        this.scan();
    }

    detached() {
        document.removeEventListener('scroll', this.scrolled);
        document.removeEventListener('resize', this.scrolled);
    }

    scrolled = () => {
        // console.log('scrolled', window.screenTop, window);
        // for (let i = 0; i < this.anchors.length; ++i) {
        //     const item = this.anchors[i];
        //     if (item.element.offsetTop + 1 >= window.scrollY) {
        //         this.active = item;
        //         break;
        //     }
        // }
    };

    scanRecursive(element: Element, root: Anchor) {
        for (let i = 0; i < element.childElementCount; ++i) {
            let child = element.children[i];

            // console.log('scanr', child);

            // child.tagName !== 'H1' && child.tagName !== 'H2' &&
            if (!child.classList.contains('spy')) {
                this.scanRecursive(child, root);
                continue;
            }

            let anchor = new Anchor();

            anchor.element = <HTMLElement>child;
            anchor.text = child.textContent;
            this.scanRecursive(child, anchor);
            root.children.push(anchor);
        }
    }

    scan() {
        let anchor = new Anchor();

        this.scanRecursive(this.root, anchor);
        this.anchors = anchor.children;

        // if (this.anchors.length !== 0) {
        //     this.active = this.anchors[0];
        // }
    }

    moveTo(anchor: Anchor) {
        anchor.element.scrollIntoView({
            behavior: 'smooth',
        });
    }
}

class Anchor {
    children: Anchor[] = [];
    element: HTMLElement;
    text: string;
}
