import { z } from 'zod';

export function createPaginatedResponseSchema<ResponseType extends z.ZodTypeAny>(itemSchema: ResponseType) {
    return z.object({
        list: z.array(itemSchema),
        total: z.number(),
    });
}

const emptySchema = z.object({});
export const emptyPaginatedResponse = createPaginatedResponseSchema(emptySchema);
