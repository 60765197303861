import { ContextProvider, createContext } from '@lit/context';
import { type ErrorService } from '../error/error-service';
export { ErrorService } from '../error/error-service';

export const errorServiceContext = createContext<ErrorService>('errorService');

export const createErrorServiceProvider = (errorService: ErrorService) =>
    new ContextProvider(document.body, {
        context: errorServiceContext,
        initialValue: errorService,
    });
