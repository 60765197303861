import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { CaseApi, createCaseApi } from 'api/case-api';

export { HttpClient };
export { CaseApi };

export const caseApiContext = createContext<CaseApi>('case-api');
export const createCaseApiProvider = (httpClient: HttpClient) =>
    new ContextProvider(document.body, {
        context: caseApiContext,
        initialValue: createCaseApi(httpClient),
    });
